.optimizationStatePanel {
    color: #555;
}

.compact {
    display: flex;
    align-items: center;
    gap: 1em;
}

.expanded {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    height: 3.5em;
    justify-content: center;
}

.statusContainer {
    display: flex;
    justify-content: space-between;
}
