.progressContainer {
    height: var(--progress-bar-height);
    border-radius: calc(var(--progress-bar-height) / 2);
    background-color: #e5e5e5;
    --progress-bar-stripe-size: 30px;
    --progress-bar-height: 0.8em;
}

.progressBarBackground {
    animation: progress-bar-stripes 1.5s linear infinite;
    background: repeating-linear-gradient(
        -45deg,
        #7882c2,
        #7882c2 calc(var(--progress-bar-stripe-size) / 2),
        #6e77b3 calc(var(--progress-bar-stripe-size) / 2),
        #6e77b3 var(--progress-bar-stripe-size)
    );
    background-size: calc(sqrt(2) * var(--progress-bar-stripe-size)) calc(sqrt(2) * var(--progress-bar-stripe-size));
    border-radius: calc(var(--progress-bar-height) / 2);
    overflow: hidden;
    height: 100%;
}

.progressBar {
    display: flex;
    align-items: center;
    height: 100%;
    transition: width 0.5s ease-in-out;
    border-radius: calc(var(--progress-bar-height) / 2);
    box-shadow: 0px 0px 0px 20000px #e5e5e5;
}

@keyframes progress-bar-stripes {
    from {
        background-position: 0 0;
    }
    to {
        background-position: calc(var(--progress-bar-stripe-size) * sqrt(2))
            calc(var(--progress-bar-stripe-size) * sqrt(2));
    }
}
