.tableView {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.tableView__header {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    gap: 8px;
    align-items: center;
    padding: 16px;
}

.tableView__table {
    padding: 0px;
    overflow-y: auto;
    height: 100%;
}
