.rowWrapper {
    position: absolute;
    height: var(--ta-row-height);
    width: 100%;
    transition: all 0.1s ease-in;
}

.rowWrapper--even {
    background-color: rgba(0, 0, 0, 0.02);
}

.rowWrapper--odd {
    background: var(--brand-white);
}

.rowWrapper--selected {
    background-color: var(--color-surface-brand-interactive6-active);
    border-bottom: 1px solid black;
    color: var(--color-text-base-inverse);
}

.rowWrapper--selected:first-child {
    border-top: 1px solid black;
}

.rowWrapper--selected .teacherNameBackgroundContent {
    color: var(--color-text-base-inverse);
}

.teacherName {
    display: flex;
    position: absolute;
    top: 0px;
    height: var(--ta-row-height);
    width: var(--ta-header-width);
    align-items: center;
    text-align: left;
}

.teacherNameBackground {
    display: table-cell;
    padding: 0.5em;
    font-size: 0.8em;
}

.hoursWrapper {
    position: relative;
    top: 0;
    left: var(--ta-header-width);
    width: calc(100% - var(--ta-header-width));
    height: 100%;
}

.blocked {
    z-index: 10;
    overflow: hidden;
    position: absolute;
    top: 0;
    height: 100%;
    opacity: 0.5;
    background-size: 6px 6px;
    border-left: 1px solid red;
    background-image: repeating-linear-gradient(-45deg, red 0, red 1px, #f7e6e6 0, #f7e6e6 50%);
}
