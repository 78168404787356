.chip {
    --chip-text-color: unset;

    display: inline-flex;
    align-items: center;
    padding-block: var(--chip-padding-vertical);
    padding-inline: var(--chip-padding-horizontal);
    block-size: var(--chip-height);
    gap: var(--chip-gap);
    border-radius: var(--chip-border-radius);
    border: var(--size-border-width-normal) solid var(--chip-border-color);
    background-color: var(--chip-fill);
    white-space: nowrap;
    color: var(--chip-text-color);
}

.chip p {
    font-size: var(--chip-font-size);
    font-weight: 400;
    white-space: nowrap;
}

.chip--size-xs {
    --chip-height: 24px;
    --chip-padding-horizontal: var(--size-space-padding-horizontal-2x);
    --chip-padding-vertical: var(--size-space-padding-vertical-1x);
    --chip-border-radius: var(--size-space-radius-1x);
    --chip-gap: var(--size-space-gap-1x);
    --chip-font-size: 12px;
}

.chip--size-md {
    --chip-height: 32px;
    --chip-padding-horizontal: var(--size-space-padding-horizontal-3x);
    --chip-padding-vertical: var(--size-space-padding-vertical-1_5x);
    --chip-border-radius: var(--size-space-radius-1x);
    --chip-gap: var(--size-space-gap-1x);
    --chip-font-size: 14px;
}

.chip--size-lg {
    --chip-height: 48px;
    --chip-padding-horizontal: var(--size-space-padding-horizontal-5x);
    --chip-padding-vertical: var(--size-space-padding-vertical-3x);
    --chip-border-radius: var(--size-space-radius-1_5x);
    --chip-gap: var(--size-space-gap-1_5x);
    --chip-font-size: 16px;
}

.chip--pill {
    --chip-border-radius: var(--size-space-radius-pill);
}

.chip--pill.chip--size-md {
    --chip-padding-horizontal: var(--size-space-padding-horizontal-3x);
}

.chip--pill.chip--size-lg {
    --chip-padding-horizontal: var(--size-space-padding-horizontal-5x);
}

.chip--variant-outlined {
    --chip-fill: var(--color-surface-base-container);
    --chip-border-color: var(--color-border-neutral-container-light);
}

.chip--variant-filled {
    --chip-fill: var(--color-surface-neutral-container-lightest);
    --chip-border-color: var(--color-border-neutral-container-lighter);
}

.chip__deleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chip__deleteIcon16 {
    display: block;
}

.chip__deleteIcon24 {
    display: none;
}

.chip--size-lg .chip__deleteIcon16 {
    display: none;
}

.chip--size-lg .chip__deleteIcon24 {
    display: block;
}

@media (pointer: coarse) {
    .chip--clickable {
        --chip-height: 48px;
        --chip-padding-horizontal: var(--size-space-padding-horizontal-4x);
        --chip-padding-vertical: var(--size-space-padding-vertical-3x);
        --chip-border-radius: var(--size-space-radius-1_5x);
        --chip-gap: var(--size-space-gap-1_5x);
        --chip-font-size: 16px;
    }

    .chip--clickable.chip--pill {
        --chip-padding-horizontal: var(--size-space-padding-horizontal-5x);
        --chip-border-radius: var(--size-space-radius-pill);
    }

    .chip__deleteIcon16 {
        display: none;
    }

    .chip__deleteIcon24 {
        display: block;
    }
}

.chip__icon {
    display: inline-flex;
}
