.scheduleChecklistPanel__contentArea {
    max-height: 85vh;
    width: 60%;
    overflow-y: scroll;
    padding: 3em;

    /* Make room for the "Klarmarkera" button */
    padding-bottom: 7em;
}

.scheduleChecklistPanel__ChecklistSubItemsContainer {
    transition: max-height 0.25s ease-out;
    max-height: 0;
    overflow: hidden;
}

.scheduleChecklistPanel__ChecklistSubItemsContainer--selected {
    max-height: 20em;
}
