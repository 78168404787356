.timeLine {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
}

.timeLineHourHeader {
    display: table;
    height: 100%;
    width: 40px;
}

.timeLineHourTextWrapper {
    display: table-cell;
    vertical-align: middle;
    font-size: 0.5em;
    font-weight: bold;
}

.timeLineHourText {
    display: inline-block;
    padding: 0px 3px 0px 3px;
    font-size: 10px;
}
