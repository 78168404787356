.mainPanel {
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.mainPanelContent {
    flex: 1;
    overflow: scroll;
}

.mainPanelFooter {
    flex: 0;
}
