.automationControlPanel {
    border-top: 1px solid #ddd;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}

.content {
    display: flex;
    align-items: center;
    width: 100%;
}

.optimizationStatePanel {
    flex-grow: 1;
}

.openPanel {
    padding: 1em 1em;

    .content {
        gap: 1.5em;
    }
}

.closedPanel {
    padding: 0.2em 1em;
    cursor: pointer;

    .content {
        gap: 0em;
    }
}

.startStopButton {
    flex-grow: 0;
}
