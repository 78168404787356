.modalWrap {
    z-index: 5 !important;
}

.modalContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalStyle {
    top: 5px;
}

.wrapper {
    display: flex;
}

.innerWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.blockedTime {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.controlBar {
    display: flex;
    flex: 0;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right: 10px;
    padding-block: var(--size-space-padding-vertical-3x);
    gap: var(--size-space-gap-2x);
}

.scheduleContainer {
    flex: 1;
    overflow: scroll;
    height: 100%;
}
