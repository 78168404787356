.lecture__content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0.7em;
    padding: 0.5em;
    padding-top: 2.3em;
    text-align: start;
}

.lecture__background {
    height: 100%;
    border-radius: 3px;
    position: relative;
    border: 1px solid #00000044;
}

.lecture__background--selected {
    border-color: #1890ff;
    box-shadow: 0 0 0 3px #1890ff;
}
