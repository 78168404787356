.input {
    padding-inline: 20px;
    padding-block: 10px;
}

.searchResults {
    padding-inline: 10px;
    padding-block: 20px;
    display: flex;
    flex-direction: column;
    border-top: 2px solid var(--color-border-neutral-container-light);
    gap: 10px;
    max-height: 60vh;
    overflow-y: auto;
    transition: border-top-color 0.2s ease-in-out;
}

.searchResults--focused {
    border-top: 2px solid var(--color-surface-primary-brand-interactive1-enabled);
}

.list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-top: 5px;
}

.item {
    display: flex;
    align-items: center;
    gap: 5px;
    transition: background-color 0.2s ease-in-out;
    padding-block: 5px;
    padding-inline: 10px;
    border-radius: 5px;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    text-align: left;
    color: var(--color-text-neutral-extra-darkest);
}

.item a,
.item button {
    flex: 1;
    text-align: left;
}

.item.selected {
    background-color: var(--color-surface-brand-interactive11-popup-row-active);
}

.item:hover {
    background-color: var(--color-surface-brand-interactive3-hovered);
    color: black;
}
.item a:hover {
    color: black;
}

.label {
    padding-inline: 10px;
}

.resultSections {
    display: grid;
    margin-top: 10px;
    grid-template-columns: repeat(5, 1fr);
}

.resultsContainer {
    display: flex;
    margin-top: 5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.icons {
    display: flex;
    align-items: center;
    gap: 5px;
}

.searchIcon {
    margin-right: 5px;
    font-size: 16px;
    color: var(--color-border-neutral-container-darkest);
}

.searchIcon--focused {
    color: var(--color-surface-primary-brand-interactive1-enabled);
}
