.searchPopup :global(.ant-select-selection-overflow-item) {
    padding-left: 4px;
    padding-right: 4px;
}

.searchPopup :global(.ant-select-selection-overflow-item:first-child) {
    padding-left: 0;
}

.searchPopup :global(.ant-select-selection-overflow-item:last-child) {
    padding-right: 0;
}

.searchPopupList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    gap: 1.5em;
    overflow: auto;
    max-height: 80vh;
    padding: 10px;
}
