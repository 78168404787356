.timestampCorner {
    position: absolute;
    color: #333333;
    font-size: 100%;
    height: 1.3em;
}

.timestampCorner--topleft {
    top: 5px;
    left: 5px;
}

.timestampCorner--bottomright {
    bottom: 5px;
    right: 5px;
}

.timestampCorner__content {
    display: flex;
    font-weight: bold;
    gap: 3px;
    align-items: center;
    padding-top: 0.2em;
    position: relative;
    top: -0.15em;
}
