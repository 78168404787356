.wrapper {
    position: relative;
    overflow-y: scroll;
    height: 100%;
}

.gradientOverlayWrapper {
    position: sticky;
    pointer-events: none;
}

.topGradientOverlayWrapper {
    top: 0;
    width: 100%;
    z-index: 10;
    height: 0;
}

.topGradientOverlay {
    width: 100%;
    height: 1rem;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
}

.bottomGradientOverlayWrapper {
    bottom: 0;
    width: 100%;
    z-index: 10;
    height: 0;
}

.bottomGradientOverlay {
    width: 100%;
    height: 1rem;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
}
